<template>
  <v-app id="login">
    <v-main class="login-panel">
      <v-container class="fill-height" fluid>
        <!-- <v-dialog v-model="showDialog" max-width="400px" persistent>
          <v-card flat class="Card-style pa-2">
            <v-container fluid>
              <v-layout justify-center>
                <v-flex xs12 sm12>
                  <v-flex xs12>
                    <label class="title text-xs-center"
                      >Email Notification</label
                    >
                    <p>Press ok to verify your account</p>
                    <p>
                      <b>{{ signupData.email }}</b>
                    </p>
                  </v-flex>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="emailVerification">OK</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card>
        </v-dialog> -->
        <template>
          <v-row justify="center" v-if="showErrordialog">
            <v-dialog v-model="showErrordialog" max-width="290">
              <v-card>
                <v-card-title class="headline">
                  {{ printErrorMessageTitle }}
                </v-card-title>

                <v-card-text>
                  {{ printErrorMessage }}
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <!-- <v-btn color="green darken-1" text @click="canceldialogPopUp">
                    Cancel
                  </v-btn> -->

                  <v-btn color="green darken-1" text @click="savedialogPopUp">
                    OK
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="showDeactivateErrordialog" max-width="290">
              <v-card>
                <v-card-title class="headline">
                  {{ printErrorMessageTitle }}
                </v-card-title>

                <v-card-text>
                  {{ printErrorMessage }}
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <!-- <v-btn color="green darken-1" text @click="canceldialogPopUp">
                    Cancel
                  </v-btn> -->

                  <!-- <v-btn color="green darken-1" text @click="savedialogPopUp">
                    OK
                  </v-btn> -->
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </template>
        <v-row align="center" justify="center">
          <v-card class="form-card" width="536">
            <v-card-text class="securityForm" v-if="index != lengthCount">
              <v-form v-model="isFormValid" ref="securityQuestionForm">
                <loading :active="isLoading" :loader="loader" />
                <v-col cols="12" v-if="index != lengthCount">
                  <v-text-field
                    v-model.trim="randomQuestion[index].description"
                    label="Question"
                    outlined
                    shaped
                    required
                  >
                  </v-text-field>
                  <v-text-field
                    label="Answer"
                    v-model="randomQuestion[index].answer"
                    outlined
                    shaped
                    required
                    @keyup.enter="verifyQuestionsAnswer(randomQuestion[index])"
                  ></v-text-field>
                </v-col>
                <v-layout>
                  <v-flex sm12 v-if="index != lengthCount" class="mx-4">
                    <v-btn
                      color="primary"
                      block
                      @click="verifyQuestionsAnswer(randomQuestion[index])"
                      :disabled="!randomQuestion[index].answer"
                      >Next</v-btn
                    >
                  </v-flex>
                </v-layout>
              </v-form>
            </v-card-text>

            <v-card-title>
              <v-card-text
                style="color: dodgerblue"
                v-if="index == lengthCount"
              >
                <span class="header">
                  Sorry, Your Account has been Deactivated for 30 mins. For
                  further queries contact Customer Service</span
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn to="/" color="primary"> sign in</v-btn>
                </v-card-actions>
                <span class="header" v-if="index != lengthCount"
                  >Verify yourself as your location is changed</span
                >
              </v-card-text>
            </v-card-title>
          </v-card>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import authAPI from "@/services/authAPI.js";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  data() {
    return {
      valid: false,
      showDialog: false,
      isLoading: false,
      loader: "bars",
      lengthCount: null,
      index: 0,
      e1: true,
      printErrorMessage: "",
      printErrorMessageTitle: "",
      loading: false,
      verifyAnswer: {},
      securityList: [],
      randomQuestion: [],
      firstQuestion: {},
      secondQuestion: {},
      thirdQuestion: {},
      isFormValid: false,
      showErrordialog: false,
      showDeactivateErrordialog: false,
      signupData: {},
    };
  },

  components: {
    Loading,
  },

  mounted() {
    this.getSecurityList();
    this.getPartySecurityQuestions();
  },

  methods: {
    clear() {
      this.$refs.securityQuestionForm.reset();
    },

    async getSecurityList() {
      try {
        let response = await authAPI.getSecurityList();
        this.securityList = response.securityQuestions;
        this.firstQuestion.quationsId =
          this.securityList.length > 0 ? this.securityList[0].questionId : null;
      } catch (error) {}
    },

    async getPartySecurityQuestions() {
      var userName = localStorage.getItem("nameOfUser");
      //var userPassword = localStorage.getItem("passwordOfUser")
      var secuturityId = {
        userLoginId: window.atob(userName),
      };
      try {
        let response = await authAPI.getPartySecurityQuestions(secuturityId);
        this.lengthCount = response.securityQuestions.length;
        this.randomQuestion = response.securityQuestions;
        //this.firstQuestion.quationsId =
        //this.securityList.length > 0 ? this.securityList[0].questionId : null;
      } catch (error) {}
    },

    async verifyQu() {
      this.loading = true;
      try {
        this.signupData = this.$store.state.signUpData;
        let response = await authAPI.createAccount(this.signupData);
        let securityDetail = {
          partyId: response.partyId,
          questionIdList: [
            this.firstQuestion,
            this.secondQuestion,
            this.thirdQuestion,
          ],
        };
        await authAPI.saveQuestions(securityDetail);
        this.showDialog = true;
      } catch (err) {
        this.$root.$emit("SHOW_SNACKBAR", {
          text: err.data.messageDetail,
          color: "error",
        });
      }
    },

    async emailVerification() {
      try {
        await authAPI.emailVerification({
          emailAddress: this.signupData.email,
        });
        this.showDialog = false;
        location.href = "/";
        this.$store.dispatch("STORE_SIGNUP_DATA", {});
      } catch (err) {
        this.$root.$emit("SHOW_SNACKBAR", {
          text: err.data.messageDetail,
          color: "error",
        });
      }
    },

    async verifyQuestionsAnswer(random) {
      this.isLoading = true;
      this.verifyAnswer.securityQuestionList = [];
      let userLoginId = localStorage.getItem("nameOfUser");
      let password = localStorage.getItem("passwordOfUser");
      this.verifyAnswer.userLoginId = window.atob(userLoginId);
      this.verifyAnswer.password = window.atob(password);
      this.verifyAnswer.geoLocation = localStorage.getItem("countryCode", "IN");
      this.verifyAnswer.securityQuestionList.push({
        questionId: random.questionId,
        answer: random.answer,
      });
      try {
        let res = await authAPI.verifySecurityAnswers(this.verifyAnswer);
        this.showDialog = true;
        if (res.status == "SUCCESS") {
          this.isLoading = false;
          localStorage.setItem("authToken", res.partyDetail.authToken);
          localStorage.setItem("sessionId", res.sessionId);
          localStorage.setItem("userDetail", JSON.stringify(res.partyDetail));
          // localStorage.setItem("auth", `${userLoginId}:${password}`);
          localStorage.setItem(
            "auth",
            btoa(`${window.atob(userLoginId)}:${window.atob(password)}`)
          );
          localStorage.setItem("saveAuth", password);
          if (res.partyDetail.statusId == "PARTY_ENABLED") {
            this.$router.push("/dashboard");
            // this.text = res.responseMessage;
            // this.$root.$emit("SHOW_SNACKBAR", {
            //   text: "You are successfully logged in",
            //   color: "success",
            // });
            setTimeout(() => {
              this.isLoading = false;
            }, 3000);
          }
        } else if (res.status == "WRONG_ANSWER") {
          this.isLoading = false;
          if (this.index != this.lengthCount) this.index++;
          this.showErrordialog = true;
          this.printErrorMessage = res.errorMessage;
        } else if (res.status == "ACCOUNT_DEACTIVATED") {
          this.isLoading = false;
          this.index = this.lengthCount;
          this.showDeactivateErrordialog = false;
          this.printErrorMessage = res.errorMessage;
        } else {
          this.isLoading = false;
          this.showErrordialog = true;
          this.printErrorMessage = res.errorMessage;
          location.href = "/";
        }
      } catch (err) {
        this.$root.$emit("SHOW_SNACKBAR", {
          text: err.errorMessage,
          color: "error",
        });
      }
    },

    canceldialogPopUp() {
      this.showErrordialog = false;
    },

    savedialogPopUp() {
      this.showErrordialog = false;
      //this.$router.push("/dashboard");
    },
  },
};
</script>

<style scoped>
.login-panel {
  background-image: url(../../assets/images/didpanel.jpg);
  background-attachment: fixed;
  background-size: cover;
}

.login-panel .form-card {
  border-radius: 10px;
  /* margin-top: 10%; */
}

.header {
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
}
</style>
